<template>
    <b-container class="pt-5 pb-5">
        <h1 class="mb-4">Binnenkort Beschikbaar</h1>

        <p>Welkom bij ons gloednieuwe ticketplatform, waar magie ontstaat en onvergetelijke ervaringen wachten om ontdekt te worden! Binnenkort kun je hier terecht voor een scala aan evenementen die je niet wilt missen. Of je nu een liefhebber bent van muziek, cultuur, sport of entertainment, wij hebben voor ieder wat wils.</p>

        <h2>Wat kun je verwachten:</h2>
        <ol>
            <li><strong>Diverse Evenementen:</strong> Van opwindende concerten tot betoverende theaterstukken, we bieden een gevarieerde selectie van evenementen die aansluiten bij verschillende interesses en smaken.</li>
            <li><strong>Eenvoudig Bestelproces:</strong> Ons gebruiksvriendelijke platform maakt het eenvoudig om tickets te bestellen voor jouw favoriete evenementen. Met slechts een paar klikken ben je klaar om deel uit te maken van de festiviteiten.</li>
            <li><strong>Exclusieve Aanbiedingen:</strong> Houd onze pagina in de gaten voor exclusieve aanbiedingen en kortingen op geselecteerde evenementen. We streven ernaar om je niet alleen een onvergetelijke ervaring te bieden, maar ook om dit toegankelijk te maken voor iedereen.</li>
            <li><strong>Nieuws en Updates:</strong> Blijf op de hoogte van de laatste nieuwtjes over aankomende evenementen, artiesten en speciale gasten. We delen graag de opwinding met jou terwijl we ons voorbereiden op de lancering.</li>
        </ol>

        <h2>Hoe kun je op de hoogte blijven:</h2>
        <ol>
            <li><strong>Schrijf je in voor onze Nieuwsbrief:</strong> Mis nooit meer een aankondiging! Meld je aan voor onze nieuwsbrief en ontvang als eerste updates over nieuwe evenementen en exclusieve aanbiedingen.</li>
            <li><strong>Volg ons op Sociale Media:</strong> Word lid van onze community op sociale media en deel de opwinding met gelijkgestemde evenementliefhebbers. We zijn te vinden op [vul hier je sociale media links in].</li>
            <li><strong>Bookmark onze Website:</strong> Sla onze website op in je favorieten en kom regelmatig terug om te zien welke nieuwe avonturen op je wachten.</li>
        </ol>

        <p>Bereid je voor op een reis vol opwinding, emotie en onvergetelijke momenten. Binnenkort opent ons ticketplatform zijn deuren, en we kunnen niet wachten om samen met jou deze reis te beginnen.</p>

        <p>Tot snel!</p>

        <p>Het Tixgo Team</p>
    </b-container>
</template>

<script>
    export default {
        metaInfo: {
            title: 'Binnenkort Beschikbaar',
        }
    }
</script>